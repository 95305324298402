import Service from '..';
import CONSTANTS from '../../Constants';
import {
    AccountForgotPasswordRequestDto,
    AccountForgotPasswordResponseDto,
    AccountLoginMailPasswordRequestDto,
    AccountLoginMailPasswordResponseDto,
    ConfirmAccountRequestDto,
    CreateAccountRequestDto,
    ResendAccountConfirmEmailRequestDto,
} from './accountDto';
import i18n from 'i18next';

interface AccountServiceInterface {
    getAccessTokenRefreshToken(
        refresh_token: string,
        email: string
    ): Promise<AccountLoginMailPasswordResponseDto | null>;
    loginMailPassword(
        request: AccountLoginMailPasswordRequestDto
    ): Promise<AccountLoginMailPasswordResponseDto | null>;
    loginAccessToken(): Promise<any>;
    createAccount(request: CreateAccountRequestDto): Promise<any>;
}

class AccountService extends Service implements AccountServiceInterface {
    public async getAccessTokenRefreshToken(
        refresh_token: string,
        email: string
    ): Promise<AccountLoginMailPasswordResponseDto | null> {
        const response = await this.post(
            'v1/auth/refresh',
            {
                refresh_token: refresh_token,
                email: email,
            },
            true
        );

        if (!response) {
            return null;
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            return responseJson as AccountLoginMailPasswordResponseDto;
        }

        const errorMessage: string = (await response.json()).errors?.[0]?.message;
        if (errorMessage.includes('email unconfirmed')) {
            throw new Error(CONSTANTS.ERROR_CODE.ACCOUNT_NOT_CONFIRMED);
        }

        if (errorMessage.includes('partner not approved')) {
            throw new Error(CONSTANTS.ERROR_CODE.ACCOUNT_NOT_APPROVED);
        }

        throw new Error(errorMessage);
    }

    public async loginMailPassword(
        request: AccountLoginMailPasswordRequestDto
    ): Promise<AccountLoginMailPasswordResponseDto | null> {
        const response = await this.post('v1/auth/login', request);

        if (!response) {
            return null;
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            return responseJson as AccountLoginMailPasswordResponseDto;
        }

        const errorMessage: string = (await response.json()).errors?.[0]?.message;
        if (errorMessage.includes('email unconfirmed')) {
            throw new Error(CONSTANTS.ERROR_CODE.ACCOUNT_NOT_CONFIRMED);
        }

        if (errorMessage.includes('partner not approved')) {
            throw new Error(CONSTANTS.ERROR_CODE.ACCOUNT_NOT_APPROVED);
        }

        throw new Error(errorMessage);
    }

    public async getById(userId: string) {
        const response = await this.get(`v1/users/${userId}`);

        if (!response) {
            return null;
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            return responseJson;
        }

        this.toast().error(i18n.t('generic_api_error') as string);
        const errorCode = (await response.json()).error_code;
        throw new Error(errorCode);
    }

    public async getInfoOnMe() {
        const response = await this.get(`v2/user`);

        if (!response) {
            return null;
        }

        if (response?.status === 200) {
            const responseJson = await response.json();
            return responseJson;
        }

        const errorCode = (await response.json()).error_code;
        throw new Error(errorCode);
    }

    public async deleteMe() {
        const response = await this.delete(`user/partners`);

        if (!response) {
            return null;
        }

        if (response?.status === 204) {
            return true;
        }

        this.toast().error(i18n.t('generic_api_error') as string);
        const errorCode = (await response.json()).error_code;
        throw new Error(errorCode);
    }

    public async updateInfo(request: {
        first_name: string;
        last_name: string;
        email: string;
        company: string;
        company_role: string;
        company_url?: string;
    }) {
        const response = await this.put(`v1/user/partners`, request);

        if (!response) {
            return null;
        }

        if (response?.status === 204 || response?.status === 200) {
            this.toast().success(i18n.t('ToastMessage.toast_account_updated') as string);
            return null;
        } else {
            this.toast().error(i18n.t('generic_api_error') as string);
            const errorCode = (await response.json()).error_code;
            throw new Error(errorCode);
        }
    }

    public async forgotPasswordConfirm(request: {
        email: string;
        token: string;
        password: string;
    }) {
        const response = await this.post(`v1/auth/partners/reset-password-confirm`, request);

        if (!response) {
            return null;
        }

        this.toastNotification(
            response,
            i18n.t('Success.password_changed') as string,
            i18n.t('generic_api_error') as string
        );

        if (response?.status === 204 || response?.status === 200) {
            return null;
        }
    }

    public async authUpdatePassword(request: { new_password: string; current_password: string }) {
        const response = await this.post(`v1/auth/change-password`, request);

        if (!response) {
            return null;
        }

        if (response?.status === 204 || response?.status === 200) {
            this.toast().success(i18n.t('Success.password_changed') as string);
            return null;
        }

        this.toast().error(i18n.t('generic_api_error') as string);

        const errorCode = (await response.json()).errors?.[0]?.message;
        throw new Error(errorCode);
    }

    public async loginAccessToken() {}

    public async forgotPassword(request: AccountForgotPasswordRequestDto) {
        const response = await this.post('v1/auth/partners/reset-password', request);

        if (!response) {
            return null;
        }

        if (response?.status === 204) {
            this.toast().success(i18n.t('ForgotPassword.forgot_password_send') as string);
            return {} as AccountForgotPasswordResponseDto;
        }

        const errorCode = (await response.json()).error_code;
        throw new Error(errorCode);
    }

    public async createAccount(request: CreateAccountRequestDto): Promise<boolean> {
        const response = await this.post('v1/user/partners', request);

        if (response && response.status !== 204) {
            const errorMessage: string = (await response.json()).errors?.[0]?.message;

            if (errorMessage.includes('user already exists')) {
                this.toastNotification(
                    response,
                    '',
                    i18n.t('Errors.user_already_exists') as string
                );

                return false;
            }

            this.toastNotification(response, '', i18n.t('generic_api_error') as string);
        }

        return response?.status === 204;
    }

    public async confirmAccount(request: ConfirmAccountRequestDto): Promise<boolean> {
        const response = await this.post('v1/confirm-account', request);

        if (response && response?.status !== 204) {
            this.toastNotification(response, '', i18n.t('generic_api_error') as string);
        }

        return response?.status === 204;
    }

    public async resendConfirmationEmail(
        request: ResendAccountConfirmEmailRequestDto
    ): Promise<boolean> {
        const response = await this.post('v1/user/partners/resend-confirmation-email', request);

        if (response) {
            this.toastNotification(
                response,
                i18n.t('CreateAccount.resend_email_success') as string,
                i18n.t('generic_api_error') as string
            );
        }

        return true;
    }
}

export default AccountService;
