import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';

import { Typography } from '../../Components/Typography';
import { Garment } from '../../Services/Garment';
import { useGetGarments } from './queries';
import { DebouncedInput } from './DebouncedInput';
import { Spinner } from '../../Components/Spinner';
import styles from './styles/garments-table.module.scss';
import { useStores } from '../../Hooks/use-stores';

const columnHelper = createColumnHelper<Garment>();

const status = ['processing', 'completed', 'incomplete'] as const;
const randomIndex = () => Math.floor(Math.random() * status.length);

const columns = [
    columnHelper.accessor('title', {
        cell: (row) => (
            <Typography className={styles.name}>{row.getValue().toLowerCase()}</Typography>
        ),
        header: () => <Typography className={styles.header}>Name</Typography>,
    }),
    columnHelper.accessor('available', {
        cell: (row) => {
            return <StatusBadge status={status[randomIndex()]} />;
        },
        header: () => <Typography className={styles.header}>Status</Typography>,
    }),
    columnHelper.accessor('updated', {
        cell: (row) => (
            <Typography className={styles.name}>
                {moment(row.getValue()).format('MMM Do, h:mm a')}
            </Typography>
        ),
        header: () => <Typography className={styles.header}>Date Modified</Typography>,
    }),
];

export const GarmentsTable = () => {
    const { userStore } = useStores();
    const [keyword, setKeyword] = useState('');

    const garmentsQuery = useGetGarments({
        partner: userStore.userInfo.partner_id,
        keyword,
    });

    const table = useReactTable({
        data: garmentsQuery.data?.garments || [],
        columns,
        manualFiltering: true,
        getCoreRowModel: getCoreRowModel(),
    });

    const handleKeywordChange = (value: string) => {
        setKeyword(value);
    };

    return (
        <div className={styles.myGarments}>
            <div className={styles.garmentsHeader}>
                <div>
                    <Typography>
                        <strong>My Garments</strong>
                    </Typography>
                </div>
                <div>
                    <DebouncedInput
                        className={styles.searchInput}
                        onChange={(value) => {
                            handleKeywordChange(value);
                        }}
                        value={keyword}
                        placeholder="Search"
                    />
                </div>
            </div>
            <div className={styles.garmentsTable}>
                <table className={styles.table}>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <th key={header.id} className={styles.tableHeader}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext()
                                              )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {garmentsQuery.isLoading ? (
                            <div className={styles.loadingContianer}>
                                <Spinner />
                            </div>
                        ) : table.getRowModel().rows.length === 0 ? (
                            <EmptyState />
                        ) : (
                            table.getRowModel().rows.map((row) => (
                                <tr key={row.id} className={styles.row}>
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id} className={styles.cell}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const EmptyState = () => {
    return (
        <div className={styles.emptyState}>
            <div>
                <FolderOpenIcon />
                <Typography variant="heading1" component="h2">
                    No Assets Yet
                </Typography>
                <Typography>
                    You’ll be able to access any processing and status updates for your garments
                    here.
                </Typography>
                <Typography>Schedule a meeting above to get started!</Typography>
            </div>
        </div>
    );
};

const FolderOpenIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
        >
            <g clipPath="url(#clip0_7_6575)">
                <path
                    opacity="0.2"
                    d="M21.8031 35H65V27.5C65 26.837 64.7366 26.2011 64.2678 25.7322C63.7989 25.2634 63.163 25 62.5 25H40L30.6656 18C30.2329 17.6754 29.7066 17.5 29.1656 17.5H12.5C11.837 17.5 11.2011 17.7634 10.7322 18.2322C10.2634 18.7011 10 19.337 10 20V65L19.4312 36.7094C19.5972 36.2116 19.9156 35.7786 20.3413 35.4718C20.767 35.165 21.2784 35 21.8031 35Z"
                    fill="#2B2B2B"
                />
                <path
                    d="M10 65V20C10 19.337 10.2634 18.7011 10.7322 18.2322C11.2011 17.7634 11.837 17.5 12.5 17.5H29.1656C29.7066 17.5 30.2329 17.6754 30.6656 18L40 25H62.5C63.163 25 63.7989 25.2634 64.2678 25.7322C64.7366 26.2011 65 26.837 65 27.5V35"
                    stroke="#2B2B2B"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 65L19.4313 36.7094C19.5972 36.2116 19.9156 35.7786 20.3413 35.4718C20.767 35.165 21.2784 35 21.8031 35H72.5C72.8962 35 73.2866 35.0941 73.6393 35.2746C73.9919 35.4551 74.2966 35.7168 74.5283 36.0382C74.7599 36.3595 74.9118 36.7314 74.9716 37.123C75.0313 37.5146 74.9972 37.9148 74.8719 38.2906L65.9656 65H10Z"
                    stroke="#2B2B2B"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7_6575">
                    <rect width="80" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

const StatusBadge = ({ status }: { status: 'incomplete' | 'completed' | 'processing' }) => {
    const statusStyles = {
        incomplete: styles.incomplete,
        completed: styles.completed,
        processing: styles.processing,
    };

    return (
        <div className={classNames([styles.statusBadge, statusStyles[status]])}>
            <Typography>{status}</Typography>
        </div>
    );
};
