import { useEffect } from 'react';
import { useWindowDimensions } from './use-window-dimensions';

interface CloseCarouselProps {
    handleSetOpen: (open: boolean) => void;
}

export const useCloseDialog = ({ handleSetOpen }: CloseCarouselProps) => {
    const { windowWidth } = useWindowDimensions();

    useEffect(() => {
        if (windowWidth < 850) {
            handleSetOpen(false);
        }
    }, [windowWidth]);
};
