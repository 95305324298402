import { observer } from 'mobx-react';
import { useTour } from './hooks/useTour';
import { useStores } from '../../Hooks/use-stores';
import { useUpdateState } from './queries';

interface TourProps {
    handleStartTour: (state: boolean) => void;
}

export const Tour = observer(({ handleStartTour }: TourProps) => {
    const mutation = useUpdateState();
    const { userStore } = useStores();

    const handleOnComplete = () => {
        handleStartTour(false);
        mutation.mutate(
            {
                partnerId: userStore.userInfo.partner_id,
                state: { onboarded: new Date().toISOString() },
            },
            {
                onSuccess: () => {
                    handleStartTour(false);
                },
                onError: (error) => {
                    console.error(error);
                },
            }
        );
    };

    useTour({ handleOnComplete });

    return null;
});
