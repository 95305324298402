import { useState } from 'react';

import './index.scss';
import { LoginForm } from './LoginForm';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export const Login = () => {
    const [forgotPassword, setForgotPassword] = useState(false);

    const handleSetFortgotPassword = (forgotPassword: boolean) => {
        setForgotPassword(forgotPassword);
    };

    return (
        <div className="Login">
            {forgotPassword ? (
                <ForgotPasswordForm handleSetForgotPassword={handleSetFortgotPassword} />
            ) : (
                <LoginForm handleSetForgotPassword={handleSetFortgotPassword} />
            )}
        </div>
    );
};
