import Service from '..';

export interface GetGarmentsResponse {
    request_id: string;
    current: number;
    next: string;
    prev: string;
    total: number;
    garments: Garment[];
}

export interface Garment {
    created: string;
    updated: string;
    id: string;
    title: string;
    partner_id: string;
    brand: Brand;
    category: string;
    description: string;
    available: boolean;
    images: Image[];
    tags: string[];
    sort: number;
    variants: Variant[];
}

export interface Brand {
    id: string;
    name: string;
    sort: number;
    available: boolean;
}

export interface Image {
    id: string;
    label: string;
    url: string;
    content_type: string;
    size: number;
    height: number;
    width: number;
}

export interface Variant {
    color: string;
    size: string;
    price: number;
}

export interface GetGarmentsParams {
    page?: number;
    pageSize?: number;
    partner: string;
    keyword?: string;
}

interface GarmentServiceInterface {}

class GarmentService extends Service implements GarmentServiceInterface {
    public async getGarments({
        page,
        pageSize,
        partner,
        keyword = '',
    }: GetGarmentsParams): Promise<GetGarmentsResponse> {
        const response = await this.get(
            `v2/garment?page=${page ?? ''}&pagesize=${
                pageSize ?? ''
            }&partner=${partner}&keywords=${keyword}`
        );

        if (!response || !response.ok) {
            throw new Error('Failed to fetch garments');
        }

        return response.json();
    }
}

export default GarmentService;
