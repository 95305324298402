import Service from '..';

export interface State {
    onboarded?: string;
    phase_one?: string;
    phase_two?: string;
    agreed_to_terms?: string;
    images_submitted?: string;
    approved_for_testing?: string;
}

export interface GetStateParams {
    partnerId: string;
}

export interface UpdateStateRequest {
    partnerId: string;
    state: State;
}

interface StateServiceInterface {}

class StateService extends Service implements StateServiceInterface {
    public async getState({ partnerId }: GetStateParams): Promise<State> {
        const response = await this.get(`v1/partner/${partnerId}/state`);

        if (!response || !response.ok) {
            throw new Error('An error ocurred while getting your partner state');
        }

        return response.json();
    }

    public async updateState({ partnerId, state }: UpdateStateRequest): Promise<void> {
        const response = await this.patch(`v1/partner/${partnerId}/state`, state);

        if (!response || !response.ok) {
            throw new Error('Failed to update state');
        }

        if (response.status === 204) {
            return;
        } else {
            throw new Error('Failed to update state');
        }
    }
}

export default StateService;
