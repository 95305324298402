import { keepPreviousData, skipToken, useQuery } from '@tanstack/react-query';
import GarmentService, { GetGarmentsParams } from '../../Services/Garment';

export const useGetGarments = ({ page, pageSize, partner, keyword }: GetGarmentsParams) =>
    useQuery({
        queryKey: ['garments', page, pageSize, partner, keyword],
        queryFn: partner
            ? () => new GarmentService().getGarments({ page, pageSize, partner, keyword })
            : skipToken,
        placeholderData: keepPreviousData,
        enabled: !!partner,
    });
