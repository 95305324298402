import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Service from '../../Services';
import StateService, { UpdateStateRequest } from '../../Services/State';

export const entitleKeys = {
    all: ['entitlements'] as const,
    byId: (id: string) => ['entitlements', id] as const,
};

/**
 * Get Stripe config
 */
interface UseGetEntitlements {
    userId: string;
}

export const useGetEntitlements = ({ userId }: UseGetEntitlements) =>
    useQuery({
        queryKey: entitleKeys.byId(userId),
        queryFn: async () => {
            const response = await fetch(`http://localhost:8000/entitlements/${userId}`);
            return await response.json();
        },
        enabled: !!userId,
        select: (data) => data.map((entitlement: any) => entitlement.lookup_key),
    });

/**
 * Send Meter Event
 */

export const useSendTryOn = () =>
    useMutation({
        mutationFn: async (userId: string) => {
            const response = await fetch(`http://localhost:8000/meters/send-event`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: userId }),
            });
            return await response.json();
        },
    });

interface UseGenDeepLink {
    partner_id: string;
}

export const useGenDeepLink = ({ partner_id }: UseGenDeepLink) =>
    useQuery({
        queryKey: ['genDeepLink'],
        queryFn: async () => {
            const response = await new Service().post('v1/deeplinks/code', {
                partner_id: 'protea',
                parameters: {},
                size: 200,
            });

            if (!response || !response.ok) {
                throw new Error('Failed to generate deep link');
            }

            return response.blob();
        },
    });

export const useGetState = ({ partnerId }: { partnerId: string }) =>
    useQuery({
        queryKey: ['state', partnerId],
        queryFn: () => new StateService().getState({ partnerId }),
        enabled: !!partnerId,
    });

export const useUpdateState = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (body: UpdateStateRequest) => new StateService().updateState(body),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['state'] });
        },
    });
};
