import { LockSimple, RocketLaunch } from '@phosphor-icons/react';
import { Link } from '../../Components/Link';
import { Typography } from '../../Components/Typography';
import CONSTANTS from '../../Constants';
import { useStores } from '../../Hooks/use-stores';
import styles from './index.module.scss';
import { Button2 } from '../../Components/Button2';
import classNames from 'classnames';
import { BackButton } from '../../Features/partner-portal/BackButton';
import { useCustomPath } from '../../Hooks/use-custom-path';
import { useGetState } from '../../Features/partner-portal/queries';
import { LoadingPage } from '../../Components/LoadingPage';
import { State } from '../../Services/State';

export const InternalTesting = () => {
    const customPath = useCustomPath({ where: CONSTANTS.SCREEN.PARTNER_PORTAL });
    const { userStore } = useStores();
    const stateQuery = useGetState({ partnerId: userStore.userInfo.partner_id });

    if (stateQuery.isLoading) {
        return <LoadingPage />;
    }

    if (stateQuery.error) {
        return <div>Error: {stateQuery.error.message}</div>;
    }

    const getPhase1Progress = (state: State | undefined) => {
        if (!state) {
            return { progress: 0, label: 'Get Started!' };
        }

        const { approved_for_testing, agreed_to_terms, images_submitted } = state;

        if (approved_for_testing) {
            return { progress: 100, label: 'Access App' };
        } else if (agreed_to_terms && images_submitted) {
            return { progress: 66, label: 'Pending Items' };
        } else if (agreed_to_terms) {
            return { progress: 33, label: 'Terms & Agreement' };
        } else {
            return { progress: 0, label: 'Get Started!' };
        }
    };

    const { progress, label } = getPhase1Progress(stateQuery?.data);

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <BackButton path={customPath} />
                <Typography variant="heading1" component="h1">
                    Select An Internal Testing
                </Typography>
                <div className={styles.cards}>
                    <PhaseCard
                        active={Boolean(stateQuery.data?.phase_one)}
                        title="Phase 1"
                        description="We’ll exchange resources to ensure that we can empower your team with the best experience. "
                        progress={progress}
                        progressLabel={label}
                        tag="Product Ingestion"
                    />
                    <PhaseCard
                        active={false}
                        title="Phase 2"
                        description="Following this, we can begin with an early live internal rollout."
                        progress={0}
                        progressLabel="Phase 1 Completion Required"
                        tag="Internal Soft Launch"
                    />
                </div>
                {!stateQuery.data?.phase_one && (
                    <Button2 variant="outlined" color="secondary">
                        <a className={styles.emailUs} href="mailto:partnerships@spreeai.com">
                            Email Us for More Info
                        </a>
                    </Button2>
                )}
                {stateQuery.data?.phase_one && !stateQuery.data?.phase_two && (
                    <Button2 variant="contained" color="secondary" asChild>
                        <Link pathConfig={{ where: CONSTANTS.SCREEN.PHASE_ONE }}>Continue</Link>
                    </Button2>
                )}
            </div>
        </div>
    );
};

interface PhaseCardProps {
    active: boolean;
    description: string;
    progress: number;
    progressLabel: string;
    tag: string;
    title: string;
}

const PhaseCard = ({
    active,
    description,
    progress,
    progressLabel,
    tag,
    title,
}: PhaseCardProps) => {
    const cardStyles = classNames([
        styles.phaseCard,
        { [styles.active]: active, [styles.inactive]: !active },
    ]);

    return (
        <article className={cardStyles}>
            <div className={styles.cardContent}>
                <div className={styles.header}>
                    {active ? <RocketLaunch fill="#2EB67D" size={32} /> : <LockSimple size={32} />}
                    <div className={styles.tag}>
                        <Typography>{tag}</Typography>
                    </div>
                </div>
                <div>
                    <Typography component="h4">{title}</Typography>
                    <Typography>{description}</Typography>
                </div>
                <div className={styles.cardFooter}>
                    <div className={styles.progress}>
                        <div className={styles.progressBar} style={{ width: `${progress}%` }} />
                    </div>
                    <Typography>{progressLabel}</Typography>
                </div>
            </div>
            {!active && <div className={styles.overlay} />}
        </article>
    );
};
