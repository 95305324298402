import { Files, Person, TreeStructure, TShirt } from '@phosphor-icons/react';
import { motion } from 'framer-motion';

import { Typography } from '../../Components/Typography';
import { CustomCarousel } from '../../Components/CustomCarousel';
import { useCustomCarousel } from '../../Components/CustomCarousel/hooks/useCustomCarousel';
import { CustomButton } from '../../Components/CustomCarousel/components/CustomButtons';
import styles from './styles/what-we-need-carousel.module.scss';
import classNames from 'classnames';

const slides = [
    {
        icon: TreeStructure,
        title: 'Metadata',
        description: "Information about a garment's materials, design, dimensions, and features",
        bgStyle: styles.bgMetadata,
    },
    {
        icon: TShirt,
        title: 'Front Flat Garment Images',
        description: 'Frontal view image of a garment, captured with the garment laid flat',
        bgStyle: styles.bgGarment,
    },
    {
        icon: Person,
        title: 'Model Garment Images',
        description: 'Images of model wearing the garments in different angles and poses',
        bgStyle: styles.bgModel,
    },
    {
        icon: Files,
        title: 'Tech Packs',
        description: 'Documentation with design details, measurements, and material specs',
        bgStyle: styles.bgTechPack,
    },
];

export const WhatWeNeedCarousel = () => {
    return (
        <div className={styles.carouselContainer}>
            <div className={styles.carouselWrapper}>
                <CustomCarousel
                    settings={{
                        dots: true,
                        arrows: false,
                        infinite: true,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        adaptiveHeight: true,
                        centerMode: true,
                        variableWidth: true,
                        appendDots: (dots) => {
                            return <CarouselControls dots={dots} />;
                        },
                    }}
                    theme="light"
                >
                    {slides.map((slide, index) => (
                        <div key={index} className={styles.slide}>
                            <div className={classNames([styles.slideContent, slide.bgStyle])}>
                                <div className={styles.overlay} />
                                <div className={styles.slideFooter}>
                                    <slide.icon size={32} fill="#2EB67D" />
                                    <div>
                                        <Typography style={{ fontWeight: 500 }} component="h5">
                                            {slide.title}
                                        </Typography>
                                        <Typography>{slide.description}</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </CustomCarousel>
            </div>
        </div>
    );
};

interface CarouselControlsProps {
    dots: React.ReactNode;
}

const CarouselControls = ({ dots }: CarouselControlsProps) => {
    const { handleNext, handlePrev } = useCustomCarousel();

    return (
        <div
            style={{
                bottom: '-100px',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <div className={styles.carouselControlsContainer}>
                <motion.ul layout className={styles.dotsList}>
                    {dots}
                </motion.ul>
                <CustomButton
                    handleClick={handleNext}
                    direction="next"
                    theme="light"
                    className={styles.nextButton}
                />
                <CustomButton
                    handleClick={handlePrev}
                    direction="prev"
                    theme="light"
                    className={styles.prevButton}
                />
            </div>
        </div>
    );
};
