import { SpeakerSlash, SpeakerHigh, Play, Pause } from '@phosphor-icons/react';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { useState, useRef, useEffect, DetailedHTMLProps, VideoHTMLAttributes } from 'react';

import styles from './index.module.scss';

interface VideoPLayerProps
    extends DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement> {
    src: string;
    showControls?: boolean;
    muteStorageKey?: string;
}

export const VideoPlayer = ({
    src,
    showControls = true,
    muteStorageKey,
    ...props
}: VideoPLayerProps) => {
    const [muted, setMuted] = useState(() => {
        if (muteStorageKey) {
            return localStorage.getItem(muteStorageKey) === 'true';
        }
        return false;
    });
    const [isPlaying, setIsPlaying] = useState(false);
    const [showPlayButton, setShowPlayButton] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const handleMute = () => {
        if (!videoRef.current) return;
        videoRef.current.muted = !videoRef.current.muted;
        setMuted(videoRef.current.muted);
        if (muteStorageKey) {
            localStorage.setItem(muteStorageKey, String(videoRef.current.muted));
        }
    };

    const handlePlay = () => {
        if (!videoRef.current) return;
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    useEffect(() => {
        if (!videoRef.current) return;
        const promise = videoRef.current.play();
        if (promise !== undefined) {
            promise
                .then(() => {
                    console.log('Video playing');
                    setShowPlayButton(false);
                })
                .catch(() => {
                    setShowPlayButton(true);
                });
        }
    }, [videoRef]);

    return (
        <div className={styles.videoContainer}>
            <video
                muted={muted}
                {...props}
                ref={videoRef}
                src={src}
                autoPlay
                loop
                controls={false}
                controlsList="nodownload"
                onContextMenu={(e) => e.preventDefault()}
            />
            {showControls && (
                <div className={styles.controls}>
                    {showPlayButton && (
                        <button
                            className={styles.button}
                            onClick={handlePlay}
                            aria-labelledby="play-video"
                        >
                            <VisuallyHidden>Play Video</VisuallyHidden>
                            {isPlaying ? (
                                <Pause size={24} fill="#ffffff" weight="fill" />
                            ) : (
                                <Play size={24} fill="#ffffff" weight="fill" />
                            )}
                        </button>
                    )}

                    <button
                        className={styles.button}
                        onClick={handleMute}
                        aria-labelledby="mute-video"
                    >
                        <VisuallyHidden>{muted ? 'Un-mute Video' : 'Mute Video'}</VisuallyHidden>
                        {muted ? (
                            <SpeakerSlash size={24} fill="#ffffff" weight="fill" />
                        ) : (
                            <SpeakerHigh size={24} fill="#ffffff" weight="fill" />
                        )}
                    </button>
                </div>
            )}
        </div>
    );
};
