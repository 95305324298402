import { Cardholder, Headset, MonitorArrowUp, Devices } from '@phosphor-icons/react';
import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import useMeasure from 'react-use-measure';

import { Link } from '../../Components/Link';
import { Typography } from '../../Components/Typography';
import CONSTANTS from '../../Constants';
import { useStores } from '../../Hooks/use-stores';
import { Tour } from '../../Features/partner-portal/Tour';

import 'shepherd.js/dist/css/shepherd.css';
import './shepherd.scss';
import styles from './index.module.scss';
import { useGetState } from '../../Features/partner-portal/queries';
import { LoadingPage } from '../../Components/LoadingPage';

export const PartnerPortal = observer(() => {
    const [startTour, setStartTour] = useState(false);

    const [ref, bounds] = useMeasure();
    const { userStore } = useStores();

    const stateQuery = useGetState({ partnerId: userStore.userInfo.partner_id });

    const handleStartTour = (state: boolean) => {
        setStartTour(state);
    };

    // Start tour if user is not onboarded
    useEffect(() => {
        if (!stateQuery.isLoading && !stateQuery.data?.onboarded && bounds.width > 850) {
            setTimeout(() => {
                handleStartTour(true);
            }, 500);
        }
    }, [bounds, stateQuery.data?.onboarded, stateQuery.isLoading]);

    if (stateQuery.isLoading) {
        return <LoadingPage />;
    }

    return (
        <main className={styles.main} ref={ref}>
            {startTour && <Tour handleStartTour={handleStartTour} />}
            <div className={styles.container}>
                <div className={styles.layout}>
                    <div>
                        <Typography variant="heading1" component="h1" style={{ color: '#2b2b2b' }}>
                            Hello, {userStore.userInfo.first_name} {userStore.userInfo.last_name}!
                        </Typography>
                    </div>
                    <div className={styles.top}>
                        <InternalTestingCard startTour={startTour} />
                    </div>
                    <div className={styles.bottom}>
                        <Link
                            className={styles.link}
                            style={{ pointerEvents: startTour ? 'none' : 'auto' }}
                            id="assets"
                            pathConfig={{ where: CONSTANTS.SCREEN.MANAGE_ASSESTS }}
                        >
                            <MonitorArrowUp fill="#2b2b2b" size={32} /> Manage Assets
                        </Link>
                        <Link
                            className={styles.link}
                            style={{ pointerEvents: startTour ? 'none' : 'auto' }}
                            id="billing"
                            pathConfig={{ where: CONSTANTS.SCREEN.BILLING }}
                        >
                            <Cardholder fill="#2b2b2b" size={32} /> Billing & Payment
                        </Link>
                        <Link
                            className={styles.link}
                            style={{ pointerEvents: startTour ? 'none' : 'auto' }}
                            id="support"
                            pathConfig={{ where: CONSTANTS.SCREEN.SUPPORT }}
                        >
                            <Headset fill="#2b2b2b" size={32} /> Contact Support
                        </Link>
                    </div>
                </div>
            </div>
        </main>
    );
});

interface InternalTestingCardProps {
    startTour: boolean;
}

const InternalTestingCard = ({ startTour }: InternalTestingCardProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const handlePointerEnter = () => {
        if (!videoRef.current) return;
        videoRef.current.play();
    };

    const handlePointerLeave = () => {
        if (!videoRef.current) return;
        setTimeout(() => {
            if (!videoRef.current) return;
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }, 300);
    };

    return (
        <article
            id="internal-testing"
            className={styles.featureCard}
            style={{ pointerEvents: startTour ? 'none' : 'auto' }}
            onPointerEnter={handlePointerEnter}
            onPointerLeave={handlePointerLeave}
        >
            <div className={styles.videoContainer}>
                <video
                    ref={videoRef}
                    src="https://superb-actor-37d8b69f79.media.strapiapp.com/internal_testing_card_on_hover_9f10bfd3b2.mp4"
                    loop
                    muted={true}
                    controls={false}
                    controlsList="nodownload"
                    onContextMenu={(e) => e.preventDefault()}
                />
            </div>
            <div className={styles.backgroundImage} />
            <div className={styles.overlay} />
            <div className={styles.featureInfo}>
                <div className={styles.featureDescription}>
                    <Devices fill="#ffffff" size={70} weight="duotone" />
                    <Typography style={{ color: '#ffffff' }}>Internal Testing</Typography>
                    <Typography style={{ color: '#ffffff' }}>
                        Test personalized experiences with the Protea app to explore its features
                        and ensure seamless integration with your business needs.
                    </Typography>
                </div>
            </div>
            <Link pathConfig={{ where: CONSTANTS.SCREEN.INTERNAL_TESTING }} />
        </article>
    );
};
