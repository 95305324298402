import { useEffect, useRef, useState } from 'react';
import { motion, useMotionValue, useSpring } from 'framer-motion';

import { Section } from '../../../../Components/Section';
import styles from './index.module.scss';
import { Typography } from '../../../../Components/Typography';
import image from '../../../../Assets/Img/Home/blurred-bg.png';
import mobileImage from '../../../../Assets/Img/Home/partner-teaser-blurred-mobile2.png';

import { FadeUp } from '../../../../Components/FadeUp';
import Copydeck from '../../../../i18n/Copydeck';

export const PartnerTeaser = () => {
    const [isHovered, setIsHovered] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const mouse = {
        x: useMotionValue(400),
        y: useMotionValue(250),
        opacity: useMotionValue(0),
        scale: useMotionValue(0.9),
        blur: useMotionValue(14),
    };

    const handleMouseMove = (event: MouseEvent) => {
        if (!containerRef.current) {
            return;
        }

        const rect = containerRef.current.getBoundingClientRect();

        mouse.x.set(event.clientX - rect.left - 120);
        mouse.y.set(event.clientY - rect.top + 30);
    };

    const smoothMouse = {
        x: useSpring(mouse.x, { stiffness: 100, damping: 20, mass: 0.5 }),
        y: useSpring(mouse.y, { stiffness: 100, damping: 20, mass: 0.5 }),
    };

    const handleMouseEnter = (event: MouseEvent) => {
        if (!containerRef.current) {
            return;
        }

        setIsHovered(true);
        const rect = containerRef.current.getBoundingClientRect();

        mouse.x.set(event.clientX - rect.left - 120);
        mouse.y.set(event.clientY - rect.top + 30);
        mouse.opacity.set(1);
        mouse.scale.set(1);
        mouse.blur.set(0);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        mouse.opacity.set(0);
        mouse.scale.set(0.9);
        mouse.blur.set(14);
    };

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }
        containerRef.current.addEventListener('mousemove', handleMouseMove);
        containerRef.current.addEventListener('mouseenter', handleMouseEnter);
        containerRef.current.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            if (!containerRef.current) {
                return;
            }
            containerRef.current.removeEventListener('mousemove', handleMouseMove);
            containerRef.current.removeEventListener('mouseenter', handleMouseEnter);
            containerRef.current.removeEventListener('mouseleave', handleMouseLeave);
        };
    });

    return (
        <Section className={styles.section}>
            <div className={styles.container}>
                <FadeUp amount={1}>
                    <Typography
                        variant="heading1"
                        component="h2"
                        style={{ color: 'white', textAlign: 'center' }}
                    >
                        {Copydeck.homePagePartnerTeaserSectionTitle}
                    </Typography>
                </FadeUp>

                <motion.div
                    ref={containerRef}
                    className={styles.imageContainer}
                    variants={{
                        hidden: { opacity: 0, filter: 'blur(14px)' },
                        visible: { opacity: 1, filter: 'blur(0px)' },
                    }}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.7 }}
                    transition={{
                        duration: 0.8,
                        ease: 'easeInOut',
                    }}
                >
                    <picture>
                        <source media="(max-width: 600px)" srcSet={mobileImage} />
                        <img
                            className={styles.image}
                            src={image}
                            alt="Blurry Logos"
                            loading="lazy"
                        />
                    </picture>
                </motion.div>
                <div className={styles.blobMobile}>
                    <Blob />
                </div>
                <motion.div
                    className={styles.blobDesktop}
                    animate={{
                        opacity: mouse.opacity.get(),
                        scale: mouse.scale.get(),
                        filter: `blur(${mouse.blur.get()}px)`,
                    }}
                    style={{ left: smoothMouse.x, top: smoothMouse.y }}
                    transition={{ duration: 0.4, transition: 'easeInOut' }}
                >
                    <Blob />
                </motion.div>
                <FadeUp>
                    <Typography
                        variant="body1"
                        component="p"
                        style={{ color: 'white', textAlign: 'center' }}
                        dangerouslySetInnerHTML={{
                            __html: Copydeck.homePagePartnerTeaserSectionFooter,
                        }}
                    />
                </FadeUp>
            </div>
        </Section>
    );
};

const Blob = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="282"
            height="278"
            viewBox="0 0 282 278"
            fill="none"
            style={{ willChange: 'transform' }}
        >
            <g filter="url(#filter0_di_12683_12007)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M138.448 21.9641C171.619 22.5682 207.999 17.6948 232.454 40.1519C257.531 63.1808 265.306 100.534 260.781 134.307C256.735 164.506 231.622 184.539 210.209 206.182C188.638 227.987 169.073 256.805 138.448 257.959C107.263 259.135 82.4433 234.582 61.0733 211.804C40.5513 189.93 25.8627 164.126 22.4381 134.307C18.5087 100.092 16.2386 60.6039 40.9594 36.6645C65.5194 12.8807 104.291 21.342 138.448 21.9641Z"
                    fill="url(#paint0_radial_12683_12007)"
                    fillOpacity="0.1"
                >
                    <animate
                        attributeName="d"
                        dur="15s"
                        repeatCount="indefinite"
                        values="
                                  M138.448 21.9641C171.619 22.5682 207.999 17.6948 232.454 40.1519C257.531 63.1808 265.306 100.534 260.781 134.307C256.735 164.506 231.622 184.539 210.209 206.182C188.638 227.987 169.073 256.805 138.448 257.959C107.263 259.135 82.4433 234.582 61.0733 211.804C40.5513 189.93 25.8627 164.126 22.4381 134.307C18.5087 100.092 16.2386 60.6039 40.9594 36.6645C65.5194 12.8807 104.291 21.342 138.448 21.9641Z;
                                  M129.869 20.0668C158.864 18.6105 182.122 41.2234 202.35 62.0104C222.191 82.4001 244.868 106.184 241.082 134.356C237.459 161.314 207.637 174.145 185.204 189.588C168.284 201.236 150.254 208.288 129.869 210.915C105.154 214.1 78.5236 220.253 58.1256 205.964C35.1567 189.876 19.3036 162.335 20.5709 134.356C21.8042 107.128 45.477 88.6584 64.3319 68.9424C83.906 48.4743 101.555 21.4888 129.869 20.0668Z;
                                  M120.794 21.8954C152.395 29.3857 160.456 68.8575 177.821 96.3204C189.443 114.7 205.118 131.67 203.937 153.388C202.801 174.277 186.211 189.282 171.936 204.566C156.886 220.681 142.66 239.642 120.794 242.413C96.7762 245.456 71.9709 236.505 54.822 219.405C37.6474 202.28 35.5075 177.319 31.5135 153.388C26.2343 121.757 10.357 87.422 28.367 60.8967C47.9014 32.1263 86.9705 13.8787 120.794 21.8954Z;
                                  M141.519 24.0805C173.756 17.8703 214.271 15.5226 234.69 41.2527C254.806 66.602 237.266 103.173 229.154 134.511C223.6 155.964 210.71 172.526 196.343 189.387C179.968 208.603 166.482 233.676 141.519 237.356C114.35 241.36 88.7215 226.098 67.7615 208.338C44.5424 188.663 17.7832 164.87 20.146 134.511C22.4578 104.81 56.3991 91.3948 78.4231 71.3563C98.6328 52.9685 114.7 29.247 141.519 24.0805Z;
                                  M138.448 21.9641C171.619 22.5682 207.999 17.6948 232.454 40.1519C257.531 63.1808 265.306 100.534 260.781 134.307C256.735 164.506 231.622 184.539 210.209 206.182C188.638 227.987 169.073 256.805 138.448 257.959C107.263 259.135 82.4433 234.582 61.0733 211.804C40.5513 189.93 25.8627 164.126 22.4381 134.307C18.5087 100.092 16.2386 60.6039 40.9594 36.6645C65.5194 12.8807 104.291 21.342 138.448 21.9641Z
                                "
                    />
                </path>
            </g>
            <defs>
                <filter
                    id="filter0_di_12683_12007"
                    x="0"
                    y="0"
                    width="282"
                    height="278"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="10" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_12683_12007"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_12683_12007"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="25" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_12683_12007" />
                </filter>
                <radialGradient
                    id="paint0_radial_12683_12007"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(20 98.3417) rotate(22.3052) scale(261.572 504.302)"
                >
                    <stop stopColor="#399DA9" />
                    <stop offset="0.65092" stopColor="#2FB481" />
                    <stop offset="1" stopColor="#2EB67D" />
                </radialGradient>
            </defs>
        </svg>
    );
};
