import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Form from '../../Components/Form';
import WhiteCard from '../../Components/WhiteCard';
import UseServices from '../../Hooks/use-services';
import { useStores } from '../../Hooks/use-stores';
import { LeftArrowIcon } from '../../Icons/left-arrow-icon';
import { theme } from '../../Style/theme';
import './index.scss';

interface ForgotPasswordFormProps {
    handleSetForgotPassword: (forgotPassword: boolean) => void;
}

export const ForgotPasswordForm = ({ handleSetForgotPassword }: ForgotPasswordFormProps) => {
    const [userOrPasswordIncorrect, setUserOrPasswordIncorrect] = useState(false);

    const { t } = useTranslation();
    const { loadingStore } = useStores();
    const { accountService } = UseServices();

    const onSubmitForgotPassword = async (values: any) => {
        if (!values) return false;

        try {
            loadingStore.addLoading();

            const response = await accountService.forgotPassword({
                email: values.email,
            });

            if (!response) {
                throw new Error('ERR0');
            }

            setUserOrPasswordIncorrect(false);
            return true;
        } catch (error: any) {
            setUserOrPasswordIncorrect(true);

            return false;
        } finally {
            loadingStore.removeLoading();
        }
    };

    return (
        <WhiteCard padding={20}>
            <button
                className="back text-body-bold cursor-pointer btn-delete-default-style"
                onClick={() => handleSetForgotPassword(false)}
            >
                <LeftArrowIcon fill={theme['element-greys-primary']} /> {t('back')}
            </button>
            <div className="forgot-content">
                <h1 className="text-body-bold">{t('Login.forgot_password')}</h1>
                <p className="text-body text-surface-grey-primary">
                    {t('ForgotPassword.enter_email_associated')}
                </p>
                <Form
                    onSubmit={onSubmitForgotPassword}
                    formHeaderLine={false}
                    className="form-container"
                    validation={[
                        {
                            required: true,
                            inputName: 'email',
                            isEmail: true,
                        },
                    ]}
                >
                    {userOrPasswordIncorrect ? (
                        <div className="account-password-incorrect">
                            {t('ForgotPassword.forgot_password_error')}
                        </div>
                    ) : null}

                    <Form.BasicInput label={t('email')} name="email" type="email" />
                    <Form.Submit text={t('send')} />
                </Form>
                <button
                    className="have-account text-body cursor-pointer btn-delete-default-style"
                    onClick={() => handleSetForgotPassword(false)}
                >
                    {t('ForgotPassword.already_have_partner_access')}
                </button>
            </div>
        </WhiteCard>
    );
};
