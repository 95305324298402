import { Files, Person, TreeStructure, TShirt } from '@phosphor-icons/react';

import { Button2 } from '../../Components/Button2';
import { Typography } from '../../Components/Typography';
import { BackButton } from '../../Features/partner-portal/BackButton';
import styles from './styles/manage-assets.module.scss';
import { MeetWithUsDialog } from './MeetWithUsDialog';
import { WhatWeNeedDialog } from './WhatWeNeedDialog';
import { GarmentsTable } from './GarmentsTable';

export const ManageAssetsDisplay = () => {
    return (
        <main className={styles.main}>
            <div className={styles.container}>
                <BackButton style={{ marginBottom: '32px' }} />
                <Typography variant="heading1" component="h1" style={{ marginBottom: '24px' }}>
                    Garment Assets
                </Typography>
                <div className={styles.cards}>
                    <div className={styles.card}>
                        <div className={styles.provideAssets}>
                            <Typography
                                variant="subheading1"
                                component="h4"
                                style={{ fontWeight: 600 }}
                            >
                                Ready to Provide Your Assets?
                            </Typography>
                            <Typography>
                                Provide us any garment images, model garment images, and tech packs
                                you’d like to see in your internal testing app.
                            </Typography>
                            <Typography>
                                You’ll be able to access any processing and status updates for your
                                garments here on this page.
                            </Typography>
                        </div>
                        <MeetWithUsDialog>
                            <Button2 color="secondary">Schedule A Meeting </Button2>
                        </MeetWithUsDialog>
                    </div>
                    <div className={styles.card}>
                        <div className={styles.requirements}>
                            <Typography
                                variant="subheading1"
                                component="h4"
                                style={{ fontWeight: 600 }}
                            >
                                What We Need From You:
                            </Typography>
                            <ul className={styles.requirementsList}>
                                <li>
                                    <TreeStructure fill="#2EB67D" size={32} />
                                    <Typography>Metadata</Typography>
                                </li>
                                <li>
                                    <TShirt fill="#2EB67D" size={32} />
                                    <Typography>Front Flat Garment Images</Typography>
                                </li>
                                <li>
                                    <Person fill="#2EB67D" size={32} />
                                    <Typography>Model Garment Images</Typography>
                                </li>
                                <li>
                                    <Files fill="#2EB67D" size={32} />
                                    <Typography>Tech Packs</Typography>
                                </li>
                            </ul>
                        </div>
                        <WhatWeNeedDialog>
                            <Button2 color="secondary" variant="outlined">
                                View More Details
                            </Button2>
                        </WhatWeNeedDialog>
                    </div>
                </div>
                <GarmentsTable />
            </div>
        </main>
    );
};
