import { useEffect } from 'react';

interface CalendlyWidgetProps {
    dataUrl: string;
}

export const CalendlyWidget = ({ dataUrl }: CalendlyWidgetProps) => {
    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
        head?.appendChild(script);

        return () => {
            head?.removeChild(script);
        };
    }, []);

    return (
        <div
            className="calendly-inline-widget"
            data-url={`${dataUrl}?hide_gdpr_banner=1`}
            style={{ minWidth: '500px', height: '500px' }}
        />
    );
};
