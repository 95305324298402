import React, {
    useState,
    forwardRef,
    useRef,
    ChangeEvent,
    useImperativeHandle,
    ForwardedRef,
    useEffect,
} from 'react';
import styles from './file-input.module.scss';
import classNames from 'classnames';
import { DownloadSimple } from '@phosphor-icons/react';

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onFileSelect?: (file: File | null) => void;
    label: string;
    id?: string;
    acceptedTypes?: string[];
    multiple?: boolean;
    maxSizeInMB?: number;
    error?: string;
}

export interface FileInputRef {
    click: () => void;
    reset: () => void;
    input: HTMLInputElement | null;
}

export const FileInput = forwardRef<FileInputRef, FileInputProps>(
    (
        {
            onFileSelect,
            label,
            id,
            acceptedTypes = ['*'],
            multiple = false,
            maxSizeInMB = 10,
            error = '',
            ...props
        },
        ref: ForwardedRef<FileInputRef>
    ) => {
        const [selectedFile, setSelectedFile] = useState<File | null>(null);
        const [errorMessage, setErrorMessage] = useState<string | null>(null);
        const fileInputRef = useRef<HTMLInputElement>(null);

        const fieldId = id || label.toLowerCase().replace(/\s+/g, '-');

        useImperativeHandle(ref, () => ({
            click: () => fileInputRef.current?.click(),
            reset: () => {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setSelectedFile(null);
                setErrorMessage(null);
            },
            input: fileInputRef.current,
        }));

        useEffect(() => {
            if (!fileInputRef.current) {
                return;
            }
            fileInputRef.current?.addEventListener('cancel', () => {
                console.log('cancel');
                setSelectedFile(null);
                onFileSelect?.(null);
            });
        }, []);

        const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
            const files = event.target.files;
            console.log(event.target.files);
            setErrorMessage(null);

            if (files && files.length > 0) {
                const file = files[0];

                // File type validation
                const fileExtension = file.name.split('.').pop()?.toLowerCase();
                const isValidType =
                    acceptedTypes.includes('*') ||
                    (fileExtension && acceptedTypes.includes(fileExtension));

                // File size validation
                const isValidSize = file.size <= maxSizeInMB * 1024 * 1024;

                if (!isValidType) {
                    setErrorMessage(
                        `Invalid file type. Accepted types: ${acceptedTypes.join(', ')}`
                    );
                    return;
                }

                if (!isValidSize) {
                    console.log('file too large');
                    setErrorMessage(`File size exceeds ${maxSizeInMB}MB limit`);
                    return;
                }

                setSelectedFile(file);

                if (onFileSelect) {
                    onFileSelect(file);
                }
            } else {
                setSelectedFile(null);
                if (onFileSelect) {
                    onFileSelect(null);
                }
            }
        };

        const handleTriggerClick = () => {
            fileInputRef.current?.click();
        };

        return (
            <div className={styles.fileInput}>
                <label htmlFor={fieldId} className={styles.label}>
                    {label}
                </label>
                <input
                    {...props}
                    ref={fileInputRef}
                    id={fieldId}
                    name={fieldId}
                    onChange={handleFileChange}
                    type="file"
                    accept={acceptedTypes.map((type) => `.${type}`).join(',')}
                    multiple={multiple}
                    className=""
                    style={{ display: 'none' }}
                />
                <button className={styles.button} onClick={handleTriggerClick} type="button">
                    <DownloadSimple size={20} />
                    {selectedFile ? (
                        <span className={styles.text}>Selected file: {selectedFile.name}</span>
                    ) : (
                        <span className={styles.text}>Choose a file...</span>
                    )}
                </button>

                {error && (
                    <div className={classNames([styles.helperText, styles.error])}>{error}</div>
                )}
            </div>
        );
    }
);
