import { useState } from 'react';

import { Button2 } from '../../Components/Button2';
import {
    DialogClose,
    DialogContent,
    DialogRoot,
    DialogTitle,
    DialogTrigger,
} from '../../Components/Dialog';
import { Typography } from '../../Components/Typography';
import { useCloseDialog } from '../../Hooks/use-close-dailog';
import styles from './styles/what-we-need-dialog.module.scss';
import { WhatWeNeedCarousel } from './WhatWeNeedCarousel';

interface WhatWeNeedDialogProps {
    children: React.ReactNode;
}

export const WhatWeNeedDialog = ({ children }: WhatWeNeedDialogProps) => {
    const [open, setOpen] = useState(false);

    const handleSetOpen = (open: boolean) => {
        setOpen(open);
    };

    useCloseDialog({ handleSetOpen });

    return (
        <DialogRoot open={open} onOpenChange={handleSetOpen}>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogContent aria-describedby="desc1 desc2">
                <div className={styles.dialogContent}>
                    <div className={styles.left}>
                        <div>
                            <DialogTitle asChild>
                                <Typography variant="heading1" component="h2">
                                    What We Need
                                </Typography>
                            </DialogTitle>
                            <Typography id="desc1">
                                We ask for garment assets along with current and past catalogs.
                                Providing us with as much information on your garment collections
                                will allow for you to access an even more enhanced and tailored
                                try-on experience.
                            </Typography>
                        </div>

                        <DialogClose asChild>
                            <Button2 color="secondary" variant="outlined">
                                Close
                            </Button2>
                        </DialogClose>
                    </div>
                    <div className={styles.right}>
                        <WhatWeNeedCarousel />
                    </div>
                </div>
            </DialogContent>
        </DialogRoot>
    );
};
