import AccountService from '../Services/Account';
import AnnoucementService from '../Services/Annoucement';
import BlogService from '../Services/Blog';
import CareerService from '../Services/Careers';
import ContactUsService from '../Services/ContactUs';
import StateService from '../Services/State';
import SupportRequestService from '../Services/SupportRequest';

const UseServices = () => {
    const contactUsService = new ContactUsService();
    const supportRequestService = new SupportRequestService();
    const accountService = new AccountService();
    const careerService = new CareerService();
    const blogService = new BlogService();
    const annoucementService = new AnnoucementService();
    const stateService = new StateService();

    return {
        accountService,
        annoucementService,
        blogService,
        careerService,
        contactUsService,
        supportRequestService,
        stateService,
    };
};

export default UseServices;
