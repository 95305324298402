import styles from './index.module.scss';

export const Spinner = () => {
    return (
        <div>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="39"
                height="38"
                viewBox="0 0 39 38"
                fill="none"
                className={styles.spinner}
            >
                <path
                    opacity="0.5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28.0907 20.4883L27.3726 17.2758L27.3726 17.2758C27.3007 16.7761 27.2289 16.2764 27.3007 15.7767C27.4444 12.7783 29.6706 10.2797 32.615 9.6372L35.2722 9.06609C35.4876 8.9947 35.7749 9.06609 35.9903 9.20887C36.2057 9.35165 36.3494 9.56582 36.4212 9.85137L36.7085 11.2792C36.8521 11.7789 36.493 12.2786 35.9903 12.4214L33.3332 12.9925C32.0405 13.2067 31.0351 14.2061 30.7478 15.4911C30.6042 15.9908 30.6042 16.4192 30.7478 16.9189L31.3942 19.7031C31.5378 20.2028 31.1787 20.7025 30.676 20.8453L29.2397 21.2022C28.737 21.345 28.2343 20.9881 28.0907 20.4883ZM35.801 19.9172C37.3049 19.9172 38.5241 18.698 38.5241 17.1941C38.5241 15.6901 37.3049 14.4709 35.801 14.4709C34.297 14.4709 33.0779 15.6901 33.0779 17.1941C33.0779 18.698 34.297 19.9172 35.801 19.9172Z"
                    fill="#2B2B2B"
                />
                <path
                    opacity="0.7"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.4946 11.1367L20.1911 10.8511C19.6884 10.7798 19.1857 10.637 18.7548 10.4228C15.9541 9.35197 14.2305 6.49641 14.5896 3.49807L14.8768 0.85667C14.8768 0.571113 15.0205 0.356946 15.2359 0.214167C15.4514 0.0713892 15.6668 0 15.9541 0L17.3903 0.142779C17.893 0.214168 18.3239 0.713892 18.2521 1.21362L17.9649 3.85501C17.8212 5.14001 18.4676 6.42502 19.6166 7.06752C20.0475 7.28169 20.4784 7.42447 20.9811 7.49586L23.8537 7.71002C23.877 7.71777 23.9013 7.72551 23.926 7.73344C24.1297 7.79858 24.3719 7.87604 24.5 8.06697C24.6436 8.28114 24.7154 8.4953 24.7154 8.78086L24.5718 10.28C24.564 10.3033 24.5562 10.3273 24.5482 10.352C24.4827 10.5545 24.4048 10.7952 24.2127 10.9225C23.9973 11.0653 23.7818 11.1367 23.4946 11.1367ZM23.0579 5.56836C24.5618 5.56836 25.781 4.34917 25.781 2.84521C25.781 1.34126 24.5618 0.12207 23.0579 0.12207C21.5539 0.12207 20.3347 1.34126 20.3347 2.84521C20.3347 4.34917 21.5539 5.56836 23.0579 5.56836Z"
                    fill="#2B2B2B"
                />
                <path
                    opacity="0.8"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.10049 12.7207C6.60445 12.7207 7.82364 11.5015 7.82364 9.99756C7.82364 8.49361 6.60445 7.27441 5.10049 7.27441C3.59654 7.27441 2.37735 8.49361 2.37735 9.99756C2.37735 11.5015 3.59654 12.7207 5.10049 12.7207ZM11.7887 15.7051C11.5733 16.1335 11.286 16.5618 10.9987 16.9187C9.13156 19.2746 5.82809 19.9885 3.02732 18.9176L0.585627 17.8468C0.370183 17.704 0.154739 17.4898 0.0829243 17.2757C-0.0607048 17.0615 0.0111098 16.776 0.0829243 16.5618L0.657441 15.2054C0.80107 14.8485 1.16014 14.6343 1.51922 14.6343H1.51922C1.66285 14.6343 1.80648 14.6343 1.9501 14.7057L4.3918 15.7765C5.61264 16.2762 7.04894 16.0621 7.98252 15.2054C8.26978 14.9198 8.55704 14.4915 8.77248 14.0632L9.8497 11.4218C10.0651 10.9221 10.6397 10.7079 11.1424 10.9221L12.5068 11.4932C12.7223 11.5646 12.9377 11.7787 13.0095 11.9929C13.0814 12.2071 13.0814 12.4926 13.0095 12.7068L11.7887 15.6337V15.7051Z"
                    fill="#2B2B2B"
                />
                <path
                    opacity="0.9"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3579 22.9153L13.8714 25.057C14.2304 25.414 14.5177 25.8423 14.805 26.2706C16.3849 28.7692 16.0976 32.1245 14.0868 34.3376L12.2915 36.3365C12.1478 36.4793 11.9324 36.622 11.6451 36.622C11.3579 36.6934 11.1424 36.5507 10.927 36.4079L9.84976 35.4084C9.41888 35.0515 9.41888 34.4804 9.77795 34.052L11.5733 32.0531C12.4351 31.1251 12.6505 29.7687 12.1478 28.5551C11.9324 28.1267 11.6451 27.7698 11.2861 27.4129L9.13162 25.5567C8.77254 25.1998 8.70073 24.6287 9.0598 24.2003L9.99339 23.0581C10.137 22.844 10.3525 22.7012 10.6397 22.7012H10.7115C10.927 22.7012 11.2142 22.7726 11.3579 22.9153ZM6.10059 32.7205C7.60454 32.7205 8.82373 31.5013 8.82373 29.9973C8.82373 28.4934 7.60454 27.2742 6.10059 27.2742C4.59663 27.2742 3.37744 28.4934 3.37744 29.9973C3.37744 31.5013 4.59663 32.7205 6.10059 32.7205Z"
                    fill="#2B2B2B"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.1856 25.8109C27.0582 25.0256 30.1462 26.382 31.6543 28.952L32.947 31.3793C33.2343 31.8076 33.0188 32.3787 32.5879 32.6643L31.2953 33.3782C31.0798 33.4495 30.7926 33.5209 30.5771 33.4495C30.3617 33.3782 30.1462 33.2354 30.0026 33.0212L28.6381 30.6654C28.0636 29.6659 26.9146 29.0234 25.7655 29.0234H25.4065C24.9038 29.0234 24.4729 29.1662 24.042 29.4518L21.6003 30.9509C21.3849 31.0937 21.1694 31.1651 20.8822 31.0937C20.6667 31.0223 20.4513 30.8795 20.3076 30.6654L19.5177 29.3804C19.3022 28.8806 19.4459 28.3095 19.8768 28.024L22.7493 26.3106H22.8212C23.252 26.0965 23.6829 25.9537 24.1856 25.8109ZM25.4943 37.4258C26.9982 37.4258 28.2174 36.2066 28.2174 34.7026C28.2174 33.1987 26.9982 31.9795 25.4943 31.9795C23.9903 31.9795 22.7711 33.1987 22.7711 34.7026C22.7711 36.2066 23.9903 37.4258 25.4943 37.4258Z"
                    fill="#2B2B2B"
                />
            </svg>
        </div>
    );
};
