import { observer } from 'mobx-react';

import { Typography } from '../../Components/Typography';
import { Button2 } from '../../Components/Button2';
import { Link } from '../../Components/Link';
import CONSTANTS from '../../Constants';
import background from '../../Assets/Img/PartnerPortal/welcome-screen.png';

import styles from './index.module.scss';
import { useStores } from '../../Hooks/use-stores';
import { FadeUp } from '../../Components/FadeUp';

export const Welcome = observer(() => {
    const { userStore } = useStores();

    return (
        <main className={styles.main}>
            <div className={styles.background}>
                <img src={background} className={styles.backgroundImage} alt="floating shapes" />

                <div className={styles.container}>
                    <div className={styles.content}>
                        <FadeUp delay={0.6}>
                            <Typography variant="heading1" component="h1">
                                Welcome,
                            </Typography>
                        </FadeUp>
                        <FadeUp delay={0.7}>
                            <Typography variant="heading1" component="h1">
                                {userStore.userInfo.first_name}
                            </Typography>
                        </FadeUp>
                        <FadeUp delay={0.8}>
                            <Typography variant="body1" component="p">
                                Step into a world of possibilities! Explore our demo app, test
                                personalized Protea experiences, and manage assets, billing, and
                                payments—all designed to elevate your partnership.
                            </Typography>
                        </FadeUp>
                        <FadeUp delay={0.9} className={styles.button}>
                            <Button2 variant="contained" color="secondary" asChild>
                                <Link
                                    pathConfig={{ where: CONSTANTS.SCREEN.PARTNER_PORTAL }}
                                    state={{ from: 'welcome' }}
                                >
                                    Continue
                                </Link>
                            </Button2>
                        </FadeUp>
                    </div>
                </div>
            </div>
        </main>
    );
});
