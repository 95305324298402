import classNames from 'classnames';

import { ArrowIcon } from '../../../Icons/arrow-icon';

import styles from '../styles/custom-buttons.module.scss';

interface CustomButtonsProps {
    handleNext: () => void;
    handlePrev: () => void;
    theme: 'dark' | 'light';
}

export const CustomButtons = ({ handleNext, handlePrev, theme }: CustomButtonsProps) => {
    return (
        <div className={styles.sliderButtons}>
            <CustomButton handleClick={handlePrev} theme={theme} direction="prev" />
            <CustomButton handleClick={handleNext} theme={theme} direction="next" />
        </div>
    );
};

interface CustomButtonProps {
    handleClick: () => void;
    theme: 'dark' | 'light';
    direction: 'next' | 'prev';
    className?: string;
}

export const CustomButton = ({ handleClick, theme, direction, className }: CustomButtonProps) => {
    const buttonClasses = classNames([
        styles.sliderButton,
        {
            [styles.lightModeButton]: theme === 'light',
            [styles.darkModeButton]: theme === 'dark',
        },
        className,
    ]);

    const buttonLabel = direction === 'next' ? 'Next page' : 'Previous page';
    const ariaLabel = direction === 'next' ? 'next-button-label' : 'prev-button-label';
    const arrowDirection = direction === 'next' ? 'right' : 'left';

    return (
        <button className={buttonClasses} onClick={handleClick} aria-labelledby={ariaLabel}>
            <span id="prev-button-label" hidden>
                {buttonLabel}
            </span>
            <ArrowIcon direction={arrowDirection} />
        </button>
    );
};
