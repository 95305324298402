import { Clock, Person, TShirt } from '@phosphor-icons/react';
import { DialogTitle } from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';

import { DialogContent, DialogRoot, DialogTrigger } from '../../Components/Dialog';
import { Link } from '../../Components/Link';
import { Typography } from '../../Components/Typography';
import CONSTANTS from '../../Constants';
import { BackButton } from '../../Features/partner-portal/BackButton';
import { useGenDeepLink, useGetState } from '../../Features/partner-portal/queries';
import { useCustomPath } from '../../Hooks/use-custom-path';
import { useStores } from '../../Hooks/use-stores';
import styles from './index.module.scss';
import { useGetGarments } from '../../Features/manage-assets/queries';

export const PendingItems = () => {
    const { userStore } = useStores();
    const phaseOnePath = useCustomPath({ where: CONSTANTS.SCREEN.PHASE_ONE });

    // Get garments to mark garment upload as complete
    const garmentsQuery = useGetGarments({ partner: userStore.userInfo.partner_id });
    const stateQuery = useGetState({ partnerId: userStore.userInfo.partner_id });

    return (
        <div className={styles.pendingItems}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <BackButton path={phaseOnePath} />
                        <Typography variant="subheading1" component="h2">
                            Next Steps for Internal Testing
                        </Typography>
                        <Typography>
                            Provide your inputs and garment assets to test our photorealistic try-on
                            technology and preview customized results.
                        </Typography>
                        <SandboxDialog>
                            <button className={styles.textButton}>Check out our app</button>
                        </SandboxDialog>
                    </div>
                    <div className={styles.cards}>
                        <ImageCaptureDialog>
                            <button className={styles.wrapperButton}>
                                <ActionCard
                                    completed={Boolean(stateQuery.data?.images_submitted)}
                                    description="Follow our instructions on how to take your photo(s)."
                                    icon={<Person size={46} fill="#000" weight="light" />}
                                    title="Take Your Photo(s)"
                                />
                            </button>
                        </ImageCaptureDialog>
                        <Link pathConfig={{ where: CONSTANTS.SCREEN.MANAGE_ASSESTS }}>
                            <ActionCard
                                completed={Boolean(garmentsQuery?.data?.garments.length)}
                                description="Upload any garment images, model garment images, and tech packs."
                                icon={<TShirt size={46} fill="#000" weight="light" />}
                                title="Provide Your Assets"
                            />
                        </Link>
                    </div>
                    <div className={styles.pendingApproval}>
                        <Clock size={24} />
                        Pending Approval
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ActionCardProps {
    completed: boolean;
    description: string;
    icon: React.ReactNode;
    title: string;
}

const ActionCard = ({ completed, description, icon, title }: ActionCardProps) => {
    return (
        <div className={styles.actionCard}>
            <div className={classNames([styles.status, completed ? styles.completed : ''])}>
                <Typography>{completed ? 'Completed' : 'Action Required'}</Typography>
            </div>
            <div>{icon}</div>
            <Typography variant="subheading1">{title}</Typography>
            <Typography>{description}</Typography>
        </div>
    );
};

interface DialogProps {
    children: React.ReactNode;
}

const SandboxDialog = ({ children }: DialogProps) => {
    const { userStore } = useStores();
    const { data, isLoading, error } = useGenDeepLink({ partner_id: userStore.userInfo.id });

    if (error) {
        toast.error(error.message);
    }

    const createImageSource = (blob: any) => {
        if (!blob) return '';

        return URL.createObjectURL(blob);
    };

    return (
        <DialogRoot>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogContent>
                <div className={styles.dialogContent}>
                    <div className={styles.left}>
                        <DialogTitle asChild>
                            <Typography variant="heading1" component="h2">
                                Check out our app!
                            </Typography>
                        </DialogTitle>
                        <Typography>
                            While we prepare your personalized testing, explore the app and try out
                            our unbranded experience by scanning the QR code to the right.
                        </Typography>
                        <Typography>
                            Discover different garments, play around with features, and get a feel
                            for what’s coming next!
                        </Typography>
                    </div>
                    <div className={styles.right}>
                        {isLoading ? (
                            <p>Loading</p>
                        ) : (
                            <img
                                src={createImageSource(data)}
                                alt="QR code"
                                className={styles.qrCode}
                            />
                        )}
                    </div>
                </div>
            </DialogContent>
        </DialogRoot>
    );
};

const ImageCaptureDialog = observer(({ children }: DialogProps) => {
    const { userStore } = useStores();
    const { data, error } = useGenDeepLink({ partner_id: userStore.userInfo.id });

    if (error) {
        toast.error(error.message);
    }

    const createImageSource = (blob: any) => {
        if (!blob) return '';

        return URL.createObjectURL(blob);
    };

    return (
        <DialogRoot>
            <DialogTrigger asChild>{children}</DialogTrigger>
            <DialogContent aria-describedby="desc1 desc2">
                <div className={styles.dialogContent}>
                    <div className={styles.left}>
                        <DialogTitle asChild>
                            <Typography variant="heading1" component="h2">
                                Scan the QR Code & Take Your Photo!
                            </Typography>
                        </DialogTitle>
                        <Typography id="desc1">
                            Take your photo to get started, and explore try-ons with unbranded
                            garments and preset models while we prepare your internal testing
                            experience.
                        </Typography>
                        <Typography id="desc2">
                            Feature up to <strong>five team members</strong> in the internal testing
                            app experience by submitting additional photos from each member — let’s
                            bring your team to life in the app!
                        </Typography>
                    </div>
                    <div className={styles.right} style={{ backgroundColor: '#F3F3F3' }}>
                        <img
                            src={createImageSource(data)}
                            alt="QR code"
                            className={styles.qrCode}
                        />
                    </div>
                </div>
            </DialogContent>
        </DialogRoot>
    );
});
