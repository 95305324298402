import React, { forwardRef, useState } from 'react';
import styles from './text-field.module.scss';
import classNames from 'classnames';

interface BaseTextFieldProps {
    label: string;
    error?: string;
    helperText?: string;
    required?: boolean;
    onValidate?: (value: string) => string | undefined;
    multiline?: boolean;
    rows?: number;
}

type TextFieldProps = BaseTextFieldProps &
    (
        | ({ multiline: true } & React.TextareaHTMLAttributes<HTMLTextAreaElement>)
        | ({ multiline?: false } & React.InputHTMLAttributes<HTMLInputElement>)
    );

const TextField = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextFieldProps>(
    (
        { label, error, helperText, required, onValidate, id, multiline, rows = 3, ...props },
        ref
    ) => {
        const [localError, setLocalError] = useState<string>();
        const [touched, setTouched] = useState(false);

        const fieldId = id || label.toLowerCase().replace(/\s+/g, '-');
        const errorId = `${fieldId}-error`;
        const helperId = `${fieldId}-helper`;

        const displayError = error || (touched && localError);

        const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setTouched(true);
            if (onValidate) {
                const validationError = onValidate(e.target.value);
                setLocalError(validationError);
            }
            props.onBlur?.(e as any);
        };

        return (
            <div className={styles.textField}>
                <label htmlFor={fieldId} className={styles.label}>
                    {label}
                    {required && <span className={styles.required}>*</span>}
                </label>

                <div>
                    {multiline ? (
                        <textarea
                            {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
                            ref={ref as React.Ref<HTMLTextAreaElement>}
                            id={fieldId}
                            rows={rows}
                            aria-invalid={!!displayError}
                            aria-required={required}
                            aria-describedby={`${displayError ? errorId : ''} ${
                                helperText ? helperId : ''
                            }`}
                            className={classNames([
                                styles.input,
                                displayError && styles.errorInput,
                            ])}
                            onBlur={handleBlur}
                        />
                    ) : (
                        <input
                            {...(props as React.InputHTMLAttributes<HTMLInputElement>)}
                            ref={ref as React.Ref<HTMLInputElement>}
                            id={fieldId}
                            aria-invalid={!!displayError}
                            aria-required={required}
                            aria-describedby={`${displayError ? errorId : ''} ${
                                helperText ? helperId : ''
                            }`}
                            className={classNames([
                                styles.input,
                                displayError && styles.errorInput,
                            ])}
                            onBlur={handleBlur}
                        />
                    )}
                </div>

                {displayError && (
                    <p
                        id={errorId}
                        className={classNames([styles.helperText, styles.error])}
                        role="alert"
                    >
                        {displayError}
                    </p>
                )}

                {helperText && !displayError && (
                    <p id={helperId} className={styles.helperText}>
                        {helperText}
                    </p>
                )}
            </div>
        );
    }
);

TextField.displayName = 'TextField';

export default TextField;
