import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Img from '../../Assets/Img/Login/Welcome.webp';
import Logo from '../../Assets/Img/White_Spree_Logo.svg';
import Form from '../../Components/Form';
import CONSTANTS from '../../Constants';
import UseServices from '../../Hooks/use-services';
import { useStores } from '../../Hooks/use-stores';
import Copydeck from '../../i18n/Copydeck';
import { AlbumIcon } from '../../Icons/album-icon';
import { DesktopIcon } from '../../Icons/desktop-icon';
import { HandshakeIcon } from '../../Icons/handshake-icon';
import { theme } from '../../Style/theme';
import './index.scss';
import { observer } from 'mobx-react';
import { useCustomPath } from '../../Hooks/use-custom-path';
import { useNavigate } from 'react-router-dom';
import { IS_DEV } from '../../Utils/object';

interface LoginFormProps {
    handleSetForgotPassword: (forgotPassword: boolean) => void;
}

export const LoginForm = observer(({ handleSetForgotPassword }: LoginFormProps) => {
    const [errorMessage, setErrorMessage] = useState('');

    const { userStore, tokenStore, loadingStore } = useStores();
    const { accountService } = UseServices();
    const partnerPortal = useCustomPath({ where: CONSTANTS.SCREEN.PARTNER_PORTAL });
    const welcome = useCustomPath({ where: CONSTANTS.SCREEN.WELCOME });
    const createAccount = useCustomPath({ where: CONSTANTS.SCREEN.CREATE_ACCOUNT });
    const dashboard = useCustomPath({ where: CONSTANTS.SCREEN.DASHBOARD });
    const navigate = useNavigate();
    const { t } = useTranslation();

    const clearState = (
        message?: string,
        clearTokens: boolean = false,
        clearLoading: boolean = false
    ) => {
        if (message) {
            setErrorMessage(message);
        }

        if (clearTokens) {
            tokenStore.clearTokens();
        }

        if (clearLoading) {
            loadingStore.removeLoading();
        }
    };

    const onSubmit = async (values: any) => {
        if (!values) return false;

        // login and get tokens
        try {
            loadingStore.addLoading();

            // login
            const response = await accountService.loginMailPassword({
                email: values.email,
                password: values.password,
            });

            if (!response) {
                throw new Error('ERR0');
            }

            // set tokens in local storage, needed for subsequent requests
            localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN, response.access_token);
            localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEY.REFRESH_TOKEN, response.refresh_token);
        } catch (error: any) {
            // display correct translated error message
            if (error.message === CONSTANTS.ERROR_CODE.ACCOUNT_NOT_CONFIRMED) {
                setErrorMessage(t('Login.account_not_confirmed'));
            } else if (error.message === CONSTANTS.ERROR_CODE.ACCOUNT_NOT_APPROVED) {
                setErrorMessage(t('Login.account_not_approved'));
            } else {
                setErrorMessage(t('Login.account_password_incorrect'));
            }

            loadingStore.removeLoading();
            return false;
        }

        // get user info
        try {
            const user = await userStore.login();

            if (!user) {
                throw new Error('ERR1');
            }

            if (!user.partner_id) {
                clearState('User not associated with a partner account', true, true);
                return false;
            }

            userStore.setUserInfo(user);

            if (IS_DEV) {
                navigate(user.partner_state?.onboarded ? partnerPortal : welcome, {
                    replace: true,
                });
            } else {
                navigate(dashboard, {
                    replace: true,
                });
            }

            loadingStore.removeLoading();
            return true;
        } catch (error) {
            // clear tokens and set error message
            clearState('An unexpected error occurred', true, true);
            return false;
        }
    };

    return (
        <>
            <div className="box login-box">
                <h1 className="title text-body-primary-font-bold">{t('Login.login_spreeai')}</h1>
                <Form
                    onSubmit={onSubmit}
                    formHeaderLine={false}
                    className="form-container"
                    validation={[
                        {
                            required: true,
                            inputName: 'email',
                        },
                        {
                            required: true,
                            inputName: 'password',
                        },
                    ]}
                >
                    {errorMessage ? (
                        <div className="account-password-incorrect">{errorMessage}</div>
                    ) : null}

                    <Form.BasicInput label={t('email')} name="email" type="email" />
                    <Form.PasswordInput label={t('password')} name="password" />

                    <Form.Submit text={t('Login.login_space')} />
                </Form>
                <button
                    className="forgot-pass text-body-primary-font cursor-pointer btn-delete-default-style"
                    onClick={() => handleSetForgotPassword(true)}
                >
                    {t('Login.forgot_password')}
                </button>

                <button
                    className="no-account text-body-primary-font cursor-pointer btn-delete-default-style"
                    onClick={() => navigate(createAccount)}
                >
                    {t('Login.no_account')}
                </button>
            </div>

            <div className="box welcome-box" style={{ backgroundImage: `url(${Img})` }}>
                <img loading="lazy" className="logo" src={Logo} alt="logo" />
                <h1 className="title deprecated-text-display-medium">{t('Login.welcome_back')}</h1>
                <div className="info-container">
                    <div className="info">
                        <HandshakeIcon fill={theme.white} width={24} />
                        <div>
                            <h3 className="text-body-bold">{t('partner_resources_link')}</h3>
                            <p className="text-body-small">{t('Login.partner_exp')}</p>
                        </div>
                    </div>
                    <div className="info">
                        <AlbumIcon fill={theme.white} width={24} />
                        <div>
                            <h3 className="text-body-bold">{Copydeck.dashboardPageManageAssets}</h3>
                            <p className="text-body-small">
                                {Copydeck.createAccountManageAssetsBenefitParagraph}
                            </p>
                        </div>
                    </div>
                    <div className="info">
                        <DesktopIcon fill={theme.white} width={24} />
                        <div>
                            <h3 className="text-body-bold">{t('view_demo')}</h3>
                            <p className="text-body-small">{t('Login.demo_exp')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
